.top_details {
    padding: 5px 10px;
    /* background-color: rgba(255,255,255,0.9); */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
}
.top_details div{
    font-size: 20px;
    width: 50%;
    
}

.dial-mail a{
    color: #000;
}


.custom_nav.scrooling{
    background-color: rgba(255,255,255,0.9);
    color: #000;
    -webkit-transition:  0.4s ease;
    transition: 0.4s ease;
    /* -webkit-box-shadow: 0 2px 5px -1px rgba(0,0,0,0.3); */
    /* -moz-box-shadow: 0 2px 5px -1px rgba(0,0,0,0.3); */
    /* box-shadow: 0 2px 5px -1px rgba(0,0,0,0.3); */
}
.notlist a{
    color: #fff ;
    
}
.scrooling > .notlist{
    display: block;
    
}

.scrooling > #menu_items ul li a {
    color: #000;
    display: block;
    padding: 0 20px;
    float: left;
    height: 60px;
    line-height:35px;
    cursor: pointer
}

.scrooling > #menu_items ul li:hover {
    color: #fff;
    transition: all 2s ease;
    transition-duration: .6s
}
/* .scrooling > #menu_items ul .tootltypes:hover:after {
    position: absolute;
    bottom: 0;
    left: 70%;
    top: 30px;
    width: 0;
    height: 0;
    margin-left: -8.0%;
    vertical-align: middle;
    content: " ";
    border-right: 15px solid transparent;
    border-bottom: 15px solid;
    border-left: 15px solid transparent;
    opacity: .95
} */
.scrooling > #menu_items ul ul{
    background-color: #fff;
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    opacity: .98;
}
.scrooling > #menu_items ul .notlist{
    height: 40px !important;

}
.scrooling > #menu_items ul .notlist a{
    border-radius: 50px !important;
    color: #fff !important;
    background: #616161 !important;
    height: 35px;
    border-right: 1px;
    line-height: 35px;
    font-size: 15px;
    transform: scale(0.8);
    
}
.logoimg {
    display: flex;
    flex-direction: row;
}
.comname {
    margin: 0;
    display: inline;
}


