body.modal-open {
    overflow: auto;
    overflow-x: hidden;
}
 body.modal-open[style] {
    padding-right: 0px !important;
}
 a{
    outline: none;
    -moz-outline:none;
}
.modal::-webkit-scrollbar {
    width: 0 !important; 
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
  background-color:rgba(0,0,0,0.85);
  width: 100%;
}
.modal-content h3{
    opacity: 0;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: transparent;
  margin: auto;
  padding: 0;
  width: 100%;
  padding-top: 50px;

}
/* The Close Button */
.close {
  color: white !important;
  position: absolute;
  margin-top:  -10px;
  right: 30px;
  font-weight: bold;
  z-index: 9999;
  transform: scale(0.8);
  opacity: 0.8;
}
.specialcross{
    color: aliceblue !important;
}
.close:hover,
.close:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
/*image like menu*/
.image_post ul,
.image_post_office ul,
.image_post_res ul,
.image_post_sho ul,
.image_post_hotl ul,
.image_post_arch ul
{
    list-style-type: none;
}
.image_post ul li img,
.image_post_office ul li img,
.image_post_res ul li img,
.image_post_sho ul li img,
.image_post_hotl ul li img,
.image_post_arch ul li img
{
    width:auto;
    height:auto;
    max-height: 535px;
    
}
.image_post ul li,
.image_post_office ul li,
.image_post_res ul li,
.image_post_sho ul li,
.image_post_hotl ul li,
.image_post_arch ul li
{
    list-style-type: none;
    position: relative;
    display: none;
}
.image_post ul li.activepostimg,
.image_post_office ul li.activepostofimg,
.image_post_res ul li.activepostresimg,
.image_post_sho ul li.activepostshoimg,
.image_post_hotl ul li.activeposthotlimg,
.image_post_arch ul li.activepostarchimg
{
    list-style-type: none;
    position: relative;
    display: block;
}
/*image like menu*/
.buttons_prev,
.buttons_prev:hover,
.buttons_prevoff,
.buttons_prevoff:hover,
.buttons_prevres,
.buttons_prevres:hover,
.buttons_prevsho,
.buttons_prevsho:hover,
.buttons_prevhotl,
.buttons_prevhotl:hover,
.buttons_prevarch,
.buttons_prevarch:hover
{
    color: white;
    text-decoration: none;
    
}
.buttons_next,
.buttons_next:hover,
.buttons_nextoff,
.buttons_nextoff:hover,
.buttons_nextres,
.buttons_nextres:hover,
.buttons_nextsho,
.buttons_nextsho:hover,
.buttons_nexthotl,
.buttons_nexthotl:hover,
.buttons_nextarch,
.buttons_nextarch:hover
{
    color: white;
    text-decoration: none;
}
.buttons_prev i,
.buttons_prevoff i,
.buttons_prevres i,
.buttons_prevsho i,
.buttons_prevhotl i,
.buttons_prevarch i
{
     left: 15px;
     top:25vh;
     position: absolute;
}
.buttons_next i,
.buttons_nextoff i,
.buttons_nextres i,
.buttons_nextsho i,
.buttons_nexthotl i,
.buttons_nextarch i
{
     right: 15px;
     top:25vh;
     position: absolute;
}
.activepostimg h3,
.activepostofimg h3,
.activepostresimg h3,
.activepostshoimg h3,
.activeposthotlimg h3,
.activepostarchimg h3

{
    color: white;
    font-size: 18px;
    font-weight: 300;
}
.activepostimg h3:nth-of-type(1),
.activepostofimg h3:nth-of-type(1),
.activepostresimg h3:nth-of-type(1),
.activepostshoimg h3:nth-of-type(1),
.activeposthotlimg h3:nth-of-type(1),
.activepostarchimg h3:nth-of-type(1)


{
    padding-top: 15px;
    color: white;
    font-weight: 200;
    font-size: 18px;
}
.activepostimg p,
.activepostofimg p,
.activepostresimg p,
.activepostshoimg p,
.activeposthotlimg p,
.activepostarchimg p



{
    color: white;
    font-weight: 200;
    display: none;
}
.slide_image_counter{
    padding-top:10px;
    color: white;
    opacity: 0;
}
.slide_image_counter a{
    background-color:rgba(115, 115, 115, 0.5);
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 12px;
    font-size: 12px;
    text-align: justify;
}
 .modal-lg {
    max-width: 100%;
  }
.morecontent span {
    display: none;
}
.morelink {
    display: block;
}
.modalsmalltext{
    color: #fff !important;
    font-weight: 200 !important;
}
/**************************************************************************
  ---------------------Slider Image Mobile Query--------------------------
****************************************************************************/

  @media only screen 
    and (min-width : 300px) 
    and (max-width : 500px)  {
        /* Modal Content */
        .modal-content {
            margin: auto;
            padding: 0;
            width: 100%;
            padding-top: 60px;
            height: auto;
        }
            /* The Close Button */
        .close {
            color: white;
            position: absolute;
            margin-top:  0px;
            right: 0px;
            font-size: 20px;
            font-weight: bold;
            z-index: 999;
         }
        .close:hover,
        .close:focus {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }
            /*image like menu*/
        .image_post,
        .image_post_office,
        .image_post_res,
        .image_post_sho,
        .image_post_hotl,
        .image_post_arch    
        {
            z-index: 555;
        }
        .image_post ul,
        .image_post_office ul,
        .image_post_res ul,
        .image_post_sho ul,
        .image_post_hotl ul,
        .image_post_arch ul 
        {
            list-style-type: none;
        }
        .image_post ul li img   
        .image_post_office ul li img,
        .image_post_res ul li img,
        .image_post_sho ul li img,
        .image_post_hotl ul li img,
        .image_post_arch  ul li img   
        {
            width:auto;
            height: auto;
            max-height:320px;
            z-index: 555;
        }
        .image_post ul li
        .image_post_office ul li,
        .image_post_res ul li,
        .image_post_sho ul li,
        .image_post_hotl ul li,
        .image_post_arch ul li 
        {
            list-style-type: none;
            position: relative;
            display: none;
            }
        .image_post ul li.activepostimg,
        .image_post_office ul li.activepostimg,
        .image_post_res ul li.activepostimg,
        .image_post_sho ul li.activepostimg,
        .image_post_hotl ul li.activepostimg,
        .image_post_arch ul li.activepostimg 
        {
            list-style-type: none;
            position: relative;
            display: block;
        }
            /*image like menu*/
        .buttons_prev,
        .buttons_prev:hover,
        .buttons_prevoff,
        .buttons_prevoff:hover,
        .buttons_prevres,
        .buttons_prevres:hover,
        .buttons_prevsho,
        .buttons_prevsho:hover,
        .buttons_prevhotl,
        .buttons_prevhotl:hover,
        .buttons_prevarch,
        .buttons_prevarch:hover
            {
            color: white;
            text-decoration: none;
            display: none;
        }
        .buttons_next,
        .buttons_next:hover,
        .buttons_nextoff,
        .buttons_nextoff:hover,
        .buttons_nextres,
        .buttons_nextres:hover,
        .buttons_nextsho,
        .buttons_nextsho:hover,
        .buttons_nexthotl,
        .buttons_nexthotl:hover,
        .buttons_nextarch,
        .buttons_nextarch:hover
            {
            color: white;
            text-decoration: none;
            display: none;
        }
        .buttons_prev i,
        .buttons_prevoff i,
        .buttons_prevres i,
        .buttons_prevsho i,
        .buttons_prevhotl i,
        .buttons_prevarch i
        {
            left: 1px;
            z-index: 999;
            opacity: 0;
            padding: 2px;
            background: rgba(115, 115, 115, 0.8);
        }
        .buttons_next i,
        .buttons_nextoff i,
        .buttons_nextres i,
        .buttons_nextsho i,
        .buttons_nexthotl i,
        .buttons_nextarch i
        
        {
            right: 1px;
            z-index: 999;
            opacity: 0;
            padding: 2px;
            background: rgba(115, 115, 115, 0.8);
        }
        .buttons_next i:hover,
        .buttons_prev i:hover,
        .buttons_nextoff i:hover,
        .buttons_nextres i:hover,
        .buttons_nextsho i:hover,
        .buttons_nexthotl i:hover,
        .buttons_nextarch i:hover,
        .buttons_prevoff i:hover,
        .buttons_prevres i:hover,
        .buttons_prevsho i:hover,
        .buttons_prevhotl i:hover,
        .buttons_prevarch i:hover
        {
           opacity: 1;
        }
        .slide_image_counter{
            padding-top:10px;
            color: white;
        }
        .activepostimg h2{
            color: white;
            font-size: 17px;
            font-weight: 300;
            padding-left: 0px;
        }
        .activepostimg h2:nth-of-type(1){
            padding-top: 5px;
        }

        .activepostimg p{
            color: white;
            font-weight: 200;
            padding-left: 0px;
        }
        .slide_image_counter a{
            background-color:rgba(115, 115, 115, 0.5);
            color: white;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 12px;
            font-size: 11px;
            text-align: justify;
        }
        .modal-lg {
            max-width: 98%;
        }  
            
  }
/*****************************************************************************
______________________________________________________________________________                  Slider Image Mobile Query   
______________________________________________________________________________
*****************************************************************************/
    @media only screen 
    and (min-width : 510px) 
    and (max-width : 768px)  {
        .modal-content {
            margin: auto;
            padding: 0;
            width: 100%;
            padding-top: 80px;
            background-color:transparent;
        }
            /* Modal Content */
        .modal-content {
            padding-top: 60px;
        }
            /* The Close Button */
        .close {
            color: white;
            position: absolute;
            margin-top:  -20px;
            right: 10px;
            font-size: 20px;
            font-weight: bold;
            z-index: 999;
         }
        .close:hover,
        .close:focus {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }
}
    @media only screen 
    and (min-width : 768px) 
    and (max-width : 1023px)  {

        .modal-content {
            margin: auto;
            padding: 0;
            width: 100%;
            padding-top: 90px;
            background-color:transparent;
        }
            /* Modal Content */
        .modal-content {
            padding-top: 60px;
        }
            /* The Close Button */
        .close {
            color: white;
            position: absolute;
            margin-top:  -20px;
            right: 10px;
            font-size: 20px;
            font-weight: bold;
            z-index: 999;
         }
        .close:hover,
        .close:focus {
            color: #fff;
            text-decoration: none;
            cursor: pointer;
        }        
}