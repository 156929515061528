@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body {
    font-family: 'Montserrat';
    margin: 0;
}

.headingg {
    margin: 3rem;
    margin-top: 170px; 
    text-align: center;
    padding-top: 2rem;
    font-size: 25px;
}

.headingg img {
    width: 90%;
    margin-left: 1rem;
    margin-right: 1rem;
}

.description {
    text-align: justify;
    margin: 3rem 7% 2rem 7%;
}
