@import url(https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
input,
label,
span,
textarea {
font-family: 'Montserrat';
    display: block;
    margin: 5px ;
    padding: 5px;
    border: none;
    
}

input:focus,
textarea:focus {
    outline: 0
}

input.question,
textarea.question {
    font-size: 35px;
    font-weight: 300;
    border-radius: 2px;
    margin: 0;
    border: none;
    width: 80%;
    background: rgba(0, 0, 0, 0);
    transition: padding-top .2s ease, margin-top .2s ease;
    overflow-x: hidden
}

input.question+label,
textarea.question+label {
    display: block;
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    width: 10%;
    border-top: 1px solid red;
    -webkit-transition: width .4s ease;
    transition: width .4s ease;
    height: 0
}

input.question:focus+label,
textarea.question:focus+label {
    width: 80%
}

input.question:focus,
input.question:valid {
    padding-top: 35px
}

input.question:focus+label>span,
input.question:valid+label>span {
    top: -90px;
    font-size: 20px;
    bottom: 20px;
    color: #333
}

textarea.question:focus+label>span,
textarea.question:valid+label>span {
    top: -140px;
    font-size: 22px;
    color: #333
}

input.question:valid+label,
textarea.question:valid+label {
    border-color: green
}

input.question:invalid,
textarea.question:invalid {
    box-shadow: none
}

input.question+label>span,
textarea.question+label>span {
    font-weight: 300;
    margin: 0;
    position: absolute;
    color: #8F8F8F;
    font-size: 30px;
    top: -76px;
    left: 0;
    z-index: -1;
    -webkit-transition: top .2s ease, font-size .2s ease, color .2s ease;
    transition: top .2s ease, font-size .2s ease, color .2s ease
}

/*
    input[type=submit] {
        -webkit-transition: opacity .2s ease, background .2s ease;
        transition: opacity .2s ease, background .2s ease;
        display: block;
        opacity: 0;
        margin: 10px 0 0;
        padding: 10px 45px;
        cursor: pointer;
        border-radius: 35px;
        background-color: #69c;
        color: #fff
    }
*/

input.question:valid~input[type=submit],
textarea.question:valid~input[type=submit] {
    -webkit-animation: appear 1s forwards;
    animation: appear 1s forwards
}

input.question:invalid~input[type=submit],
textarea.question:invalid~input[type=submit] {
    display: none
}

@-webkit-keyframes appear {
    100% {
        opacity: 1
    }
}

@keyframes appear {
    100% {
        opacity: 1
    }
}

@media only screen and (min-width :510px) and (max-width :768px) {
    input.question:focus+label,
    textarea.question:focus+label {
        width: 100%
    }
    input.question,
    textarea.question {
        font-size: 35px;
        font-weight: 300;
        border-radius: 2px;
        margin: 0;
        border: none;
        width: 100%;
        background: rgba(0, 0, 0, 0);
        transition: padding-top .2s ease, margin-top .2s ease;
        overflow-x: hidden
    }
}
