/* Container styling */
.our-brands-container {
    text-align: center;
    padding-bottom: 20px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  /* Heading style */
  .heading {
    font-size: 3rem; /* Larger font size for "Our Brands" */
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Center aligns the content */
    align-items: center;
    gap: 0.2rem;
  }
  
  .heading .highlight {
    color: #d61c34; /* Red color for "Our" */
  }
  
  /* Slider styling */
  .brands-slider {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    padding: 10px 0;
  }
  
  .slider-card {
    flex: 0 0 auto;
    width: 250px; /* Card width */
    height: 280px;
    scroll-snap-align: center;
    border-radius: 28px;
    background-color: #f9f9f9;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
  }
  
  /* Remove scrollbar styles */
  .brands-slider::-webkit-scrollbar {
    display: none;
  }
  
  .brands-slider {
    scrollbar-width: none;
  }
  
  /* Relationship text */
  .relationship-text {
    font-size: 4rem; /* Larger font size for the text */
    color: #d61c34;
    margin: 30px 0px;
    font-weight: 300;
    line-height: 1.4;
  }
  
  /* Button styling */
  .start-project-btn {
    background-color: white;
    border: 1px solid #333;
    padding: 12px 24px;
    font-size: 18px;
    color: #333;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .start-project-btn:hover {
    background-color: #d61c34;
    color: white;
  }
  
  /* Responsive design */
  @media (max-width: 1024px) {
    .heading {
      font-size: 32px; /* Adjust heading size for tablets */
    }
  
    .relationship-text {
      font-size: 22px; /* Adjust relationship text size for tablets */
    }
  
    .start-project-btn {
      font-size: 16px;
      padding: 10px 20px;
    }
  }
  
  @media (max-width: 768px) {
    .heading {
      font-size: 28px; /* Adjust heading size for smaller screens */
    }
  
    .relationship-text {
      font-size: 20px; /* Adjust relationship text size for smaller screens */
    }
  
    .slider-card {
      width: 200px; /* Adjust card size */
      height: 150px;
    }
  }
  
  @media (max-width: 480px) {
    .heading {
      font-size: 2rem; /* Adjust heading size for mobile */
    }
  
    .relationship-text {
        font-size: 1.5rem;

    }
  
    .slider-card {
      width: 180px; /* Further adjust card size */
      height: 200px;
    }
  
    .start-project-btn {
      font-size: 14px;
      padding: 8px 16px;
    }
  }
  