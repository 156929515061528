/* Container styles */
.design-container {
    text-align: center;
    margin: 2rem auto;
  }
  
  /* Title container */
  .design-title {
    display: flex;
    justify-content: center; /* Center aligns the content */
    align-items: center;
    gap: 0.2rem; /* Optional spacing between words */
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .design-highlight {
    color: #e63946; /* Red color for "What" */
  }
  
  /* Card grid container */
  .design-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
  }
  
  /* Individual card styles */
  .design-card {
    position: relative;
    width: 16rem;
    height: 16rem;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .design-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .design-card-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .design-card-title {
    color: white;
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  /* Footer styles */
  .design-footer {
    display: flex; /* Use flexbox for proper alignment */
    justify-content: flex-end; /* Align to the right */
    margin-top: 1.5rem;
    margin-right: 1rem; /* Spacing from the right edge */
  }
  
  .design-link {
    font-weight: 500;
    text-decoration: none;
    font-size: 1rem;
    white-space: nowrap;  /* Ensures text stays in one line */
    display: inline-flex; /* Ensures inline alignment for child spans */
    align-items: center; /* Vertical alignment */
    gap: -0.1rem; /* Adds slight spacing between "Explore Our" and "Designs" */
  }
  
  .design-link-red {
    color: #e63946; /* Red color for "Explore Our" */
    margin-right: -10px;
  }
  
  .design-link-black {
    color: black; /* Black color for "Designs" */
  }
  
  /* Optional hover effect */
  .design-link:hover {
    text-decoration: underline;
  }
  