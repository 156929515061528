/* General container styling */
.testimonials-container {
    padding: 30px;
    background-color: #2c3e50; /* Dark background */
    color: #ffffff; /* Text color */
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  /* Title Styling */
  .testimonials-title {
    font-size: 3rem;
    margin-bottom: 30px;
    color: #ffffff;
  }
  
  /* Grid layout for testimonials */
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 30px;
    justify-content: center;
    align-items: stretch;
  }
  
  /* Individual testimonial card */
  .testimonial-card {
    background-color: #ffffff;
    color: #2c3e50;
    border-radius: 28px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    min-height: 450px; /* Increased card height */
    max-width: 500px; /* Increased card width */
    margin: 0 auto; /* Center cards */
  }
  
  /* Hover Effect */
  .testimonial-card:hover {
    transform: scale(1.02);
  }
  
  /* Avatar circle */
  .avatar {
    width: 100px;
    height: 100px;
    background-color: #e0e0e0;
    border-radius: 50%;
    margin: 0 auto 30px auto;
  }
  
  /* Text Styling */
  .testimonial-card h3 {
    font-size: 2rem;
    font-weight: 600;
    margin: 15px 0;
  }
  
  .testimonial-card p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonials-title {
      font-size: 1.8rem;
    }
    .testimonials-grid {
      grid-template-columns: 1fr; /* Single column for smaller screens */
    }
    .testimonial-card {
      max-width: 90%; /* Adjust card width for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .testimonials-title {
      font-size: 1.6rem;
    }
    .testimonial-card p {
      font-size: 1rem;
    }
  }
  