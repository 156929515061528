/* Frame Container */
.frame-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: auto;
  margin-top: 180px;
  overflow: hidden;
}

/* Left Section */
.frame-left {
  flex: 2.5;
}

.interior-image {
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 48px;
  object-fit: cover;
}

/* Right Section */
.frame-right {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 28px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Prevent overflow on smaller screens */
}

.form-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.form-content {
  text-align: center;
}

.header-logo {
  height: 50px;
  width: 50px;
}

h2 {
  font-size: 24px;
}

p {
  margin: 20px;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  color: #000;
}

/* Label Styling */
form label {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: #f9f9f9;
}

form label:hover {
  background-color: #f0f0f0;
}

form input[type="radio"] {
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  transition: all 0.3s ease;
  background-color: #fff;
}

form input[type="radio"]:checked {
  border-color: #d32f2f;
  background-color: #d32f2f;
}

form label:has(input[type="radio"]:checked) {
  background-color: #d32f2f;
  color: #fff;
  border-color: #d32f2f;
}

/* Button Styles */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.back-button {
  background-color: #444;
  color: #fff;
}

.next-button {
  background-color: #d32f2f;
  color: #fff;
}

/* Room Counter */
.room-counter {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-bottom: 12px; 
}

.room-counter-controls {
  display: flex;
  align-items: center;
  gap: 4px;
}

.room-counter button {
  width: 30px;
  height: 35px;
  border: none;
  border-radius: 50%;
  background-color: #d32f2f;
  color: #fff;
  font-size: 18px;
  margin-right: 8px;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.room-counter span {
  flex: 1;
  text-align: left;
  font-weight: 500;
}

/* Step 3 Form Styling */
.step3-form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #d32f2f;
  box-shadow: 0 0 4px rgba(211, 47, 47, 0.3);
}
@media (max-width: 768px) {
  .frame-container {
    flex-direction: column;
    padding: 0;
    margin: 20px;
  }
  .frame-left,
  .frame-right {
    flex: none;
    width: 100%;
  }

  .frame-right {
    margin: 10px 0;
    padding: 20px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }
  .step3-form {
    gap: 10px;
  }
}
@media (max-width: 768px) {
  .interior-image {
    margin-top: 120px;
    height: 300px;
  }
}
