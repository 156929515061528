/* General styling */
.design-process {
    text-align: center;
    background-color: #394a5f;
    color: #fff;
    padding: 40px 100px;
  }
  
  .design-process-title {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  /* Steps container */
  .design-process-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Reduced min size for smaller cards */
    gap: 55px;
    margin-top: 10px;
  }
  
  /* Individual step */
  .design-step {
    background-color: #fff;
    color: #000;
    border-radius: 28px;
    padding: 10px; /* Reduced padding */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .design-step:hover {
    transform: scale(1.05);
  }
  
  .design-step-image {
    width: 100%;
    height: 60%;
    border-radius: 28px; /* Slightly smaller border radius */
    margin-bottom: 10px; /* Reduced margin */
  }
  
  .design-step-title {
    font-size: 1.5rem; /* Slightly smaller font size */
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .design-process-title {
      font-size: 2rem; /* Adjusted title size for tablets */
    }
  
    .design-step {
      padding: 10px; /* Smaller padding for tablets */
    }
  }
  
  @media (max-width: 480px) {
    .design-process{
        padding: 60px 60px;
    }
    .design-process-steps {
      grid-template-columns: 1fr; /* Single column for smaller screens */
    }
  
    .design-step-title {
      font-size: 1.2rem; /* Adjusted font size for small screens */
    }
  
    .design-step {
      padding: 8px; /* Compact padding for mobile */
    }
  }
  