/* Main container for the component */
.why-choose-us-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping of items for smaller screens */
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
  gap: 20px; /* Add space between the two sections */
}

/* Left text section */
.text-section {
  flex: 1;
  margin-right: 20px;
  margin-left: 120px;
  min-width: 250px; /* Set minimum width for better responsiveness */
}

.text-section h2 {
  font-size: 3.5rem; /* Use rem for better scaling */
  color: #333;
  margin-bottom: 20px;
}

.text-section .highlight {
  font-size: larger;
  color: #d32f2f;
}

.text-section ul {
  list-style-type: disc;
  margin-left: 20px;
  padding: 0;
}

.text-section ul li {
  font-size: 2rem; /* Use rem for better scaling */
  color: #000;
  margin-bottom: 10px;
  line-height: 1.6;
  margin-inline-start: 20px;
}

/* Right image section */
.image-section {
  flex: 1;
  text-align: center;
  min-width: 250px; /* Set minimum width for better responsiveness */
}

.image-section .illustration {
  max-width: 100%;
  height: auto;
}

/* Media Queries for smaller devices */

/* Tablet Portrait */
@media (max-width: 768px) {
  .text-section h2 {
    font-size: 2.5rem; /* Adjust font size for tablets */
  }

  .text-section ul li {
    font-size: 1.2rem; /* Adjust font size for better readability */
  }
}

/* Mobile Devices */
@media (max-width: 480px) {
  .why-choose-us-container {
    flex-direction: column; /* Stack the sections on smaller screens */
    padding: 20px;
  }

  .text-section {
    margin-right: 0;
    margin-left: 0;
    text-align: center;
  }

  .text-section h2 {
    font-size: 2rem; /* Adjust font size for mobile */
  }

  .text-section ul li {
    font-size: 1.1rem; /* Adjust font size for mobile */
  }
}
